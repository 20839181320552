@media screen and (max-width: 600px) {
	* {
		font-size: 0.9rem;
	}
	#main {
		display: flex;
		flex-direction: column;
		padding-bottom: 10px;
	}

	#Backlog {
		height: max-content;
	}

	#BacklogMain {
		margin-top: 5px;
	}

	#update-button {
		margin-right: 1rem;
	}
}
@media screen and (max-width: 1300px) {
	* {
		font-size: 0.95rem;
	}
}
